const bookRouter = {
    route: '/paper',
    name: null,
    title: '试卷',
    type: 'view', // 类型: folder, tab, view
    name: Symbol('paper'),
    icon: 'iconfont icon-tushuguanli',
    filePath: 'view/book/book-list.vue', // 文件路径
    order: 1,
    inNav: true,
    // permission: ['查询'],
  }
  
  export default bookRouter