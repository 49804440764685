const examRouter = {
  route: '/exam',
  name: null,
  title: '试卷',
  type: 'view', // 类型: folder, tab, view
  name: Symbol('exam'),
  icon: 'iconfont icon-tushuguanli',
  filePath: 'view/exam/exam.vue', // 文件路径
  order: null,
  inNav: false,
}

export default examRouter
