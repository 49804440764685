const addPaperRouter = {
    route: '/addPaper',
    name: null,
    title: '添加试卷',
    type: 'view', // 类型: folder, tab, view
    name: Symbol('addPaper'),
    icon: 'iconfont icon-tushuguanli',
    filePath: 'view/book/book.vue', // 文件路径
    order: 1,
    inNav: true,
    // permission: ['查询'],
  }
  
  export default addPaperRouter